var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax"},[_c('div',{staticClass:"bg",style:({
      'background-image': `url(${require(`@/assets/images/parallax_${_vm.image}.jpg`)})`,
      bottom: `${this.parallaxOffset}px`,
    })}),_c('div',{staticClass:"animation"},[_c('img',{style:({
        opacity: 0.5 - (_vm.effectPrc / 100) * 0.75,
        bottom: `${this.parallaxOffset}px`,
        transform: `scale(${1 + _vm.effectPrc / 100})`,
        // bottom: `${this.parallaxOffset * 2}px`,
        // filter: `blur(${effectPrc/20}px)`,
      }),attrs:{"src":require("../assets/images/logo_ico_mono.svg"),"alt":"Taproot logo"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }