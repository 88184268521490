<template>
  <div class="content home">
    <parallax :image="randomParallaxImage"> </parallax>

    <div class="section">
      <div class="content_wrapper">
        <h3>
          Taproot is the premier crude oil and produced water gathering and
          transportation company in the Northeast DJ Basin.
        </h3>
        <p>
          Taproot Energy Partners (“Taproot" or "TEP”) is a diversified and
          innovative midstream company focused on the design, construction and
          operation of world-class midstream assets. Taproot's core business is
          the gathering and transportation of crude oil and produced water and
          the sourcing and delivery of fresh water. Taproot develops cost
          effective and innovative midstream solutions that serve as a conduit
          to safely and efficiently deliver products to market.
        </p>
      </div>
    </div>
    <div class="section">
      <div class="content_wrapper">
        <img
          class="image"
          src="../assets/images/pic2.jpg"
          alt="Field operations"
        />
      </div>
    </div>

    <!-- <div class="section">
      <div class="content_wrapper">
        <div class="left-25">
          <animated-logo></animated-logo>
        </div>
        <div class="right-75">
          <h3>Leads as the #1 Rated Company</h3>
          <p>
            Taproot has secured its position as a leader in the industry by
            consistently achieving the top rank in safety standards. Renowned
            for its unwavering commitment to operational excellence, Taproot has
            earned the prestigious title of being rated #1 in safety
            performance, setting a benchmark for the entire midstream sector.
            This achievement reflects the company's dedication to ensuring the
            secure and efficient transportation of oil and gas resources while
            prioritizing the well-being of its workforce and the environment.
          </p>
        </div>
      </div>
    </div> -->


  </div>
</template>

<script>
import Parallax from "@/components/Parallax.vue";
// import AnimatedLogo from "@/components/AnimatedLogo.vue";
export default {
  name: "Home-view",
  components: {
    Parallax,
    // AnimatedLogo,
  },
  computed: {
    randomParallaxImage() {
      return Math.floor(Math.random() * 3) + 1;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.left-25 {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  .animation {
    margin: 22px;
    width: 60%;
  }
}
.right-75 {
  display: inline-block;
  width: 75%;
  h3 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 700px) {
  .left-25,
  .right-75 {
    width: 100%;
  }

  .left-25 {
    text-align: center;
    .animation {
      margin: 0 auto;
      width: 100%;
      max-width: 100px;
    }
  }
}
</style>
