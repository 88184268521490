<template>
  <div class="parallax">
    <div
      class="bg"
      :style="{
        'background-image': `url(${require(`@/assets/images/parallax_${image}.jpg`)})`,
        bottom: `${this.parallaxOffset}px`,
      }"
    ></div>
    <div class="animation">
      <img
        :style="{
          opacity: 0.5 - (effectPrc / 100) * 0.75,
          bottom: `${this.parallaxOffset}px`,
          transform: `scale(${1 + effectPrc / 100})`,
          // bottom: `${this.parallaxOffset * 2}px`,
          // filter: `blur(${effectPrc/20}px)`,
        }"
        src="../assets/images/logo_ico_mono.svg"
        alt="Taproot logo"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-parallax",
  components: {},
  props: {
    image: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      parallaxOffset: 0,
    };
  },
  computed: {
    effectPrc() {
      return Math.floor(
        Math.min((Math.abs(this.parallaxOffset) / 600) * 100, 100)
      );
    },
  },
  methods: {
    setParallaxOffset() {
      let speed_index = 1.4;
      let pageYOffset =
        window.pageYOffsett !== undefined
          ? window.pageYOffsett
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;

      let offset_px = pageYOffset * -1 * speed_index;
      this.parallaxOffset = offset_px;
    },
  },
  mounted() {
    document.addEventListener("scroll", this.setParallaxOffset);
  },
  destroyed() {
    document.removeEventListener("scroll", this.setParallaxOffset);
  },
};
</script>

<style lang="scss" scoped>
.parallax {
  position: relative;
  height: 600px;
  width: 100%;
  overflow: hidden;

  .bg {
    position: absolute;
    height: 160%;
    width: 100%;
    bottom: 0;
    background-position: bottom;
    background-size: cover;
  }

  .animation {
    position: relative;
    height: 600px;
    width: 100%;
    margin: 0 auto 0 auto;
    text-align: center;
    img {
      max-height: 50%;
      margin-top: 120px;
      // filter: drop-shadow(9px 3px 10px#000);
    }
  }
}

@media screen and (max-width: 700px) {
  .parallax {
    position: relative;
    height: 420px;
    .animation {
      height: 420px;
    }
  }
}
</style>
